/*=================================================================*/
/*                     BOOTSTRAP ELEMENTS
/*=================================================================*/
/* === Form Control === */
.form-control {
    display: block;
    border-radius: 30px;
    width: 100%;
    height: 60px;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: $colordefault;
    background-color: #FFF;
    background-image: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    @extend .shadow-dark;
}

.form-control:focus {
    @extend .shadow-dark;
    outline: 0;
}

.form-control::-webkit-input-placeholder { /* Edge */
    color: #AEACCA;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AEACCA;
}

.form-control::placeholder {
    color: #AEACCA;
}

.form-group {
    margin-bottom: 30px;
}

.input-group-addon {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: transparent;
    border: 0;
    border-radius: 0;
}

/* === Alert === */
.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 20px;
}

.alert-dismissable .close, .alert-dismissible .close {
    position: relative;
    top: 0;
    right: 0;
    color: inherit;
}

/* === Social Icons === */
.social-icons {
    li {
        &:not(:last-child) {
            margin-right: 1.5rem;
        }
        a {
            font-size: 21px;
        }
    }
    &.light {
        li {
            a:hover {
                color: $coloryellow;
            }
        }
    }
}

/* === Helper === */
.rounded {
    border-radius: 20px !important;
}

.bg-white {
    background: #FFF;
}

.shadow-dark {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(69,67,96,0.1);
    -moz-box-shadow: 0px 5px 20px 0px rgba(69,67,96,0.1);
    box-shadow: 0px 5px 20px 0px rgba(69,67,96,0.1);
}

.shadow-light {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
    -moz-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
    box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
}

.shadow-blue {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(108,108,229,0.5);
    -moz-box-shadow: 0px 5px 20px 0px rgba(108,108,229,0.5);
    box-shadow: 0px 5px 20px 0px rgba(108,108,229,0.5);
}

.shadow-pink {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(249,123,139,0.5);
    -moz-box-shadow: 0px 5px 20px 0px rgba(249,123,139,0.5);
    box-shadow: 0px 5px 20px 0px rgba(249,123,139,0.5);
}

.shadow-yellow {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(249,215,76,0.5);
    -moz-box-shadow: 0px 5px 20px 0px rgba(249,215,76,0.5);
    box-shadow: 0px 5px 20px 0px rgba(249,215,76,0.5);
}

.padding-30 {
    padding: 30px;
}

.text-light {
    h1, h2, h3, h4, h5, h6 {
        color: #FFF;
    }
}

