/*=================================================================*/
/*                      SECTIONS                             
/*=================================================================*/

section {
	padding-top: 110px;
	position: relative;
}

section.home {
	background: $colordark;
	padding: 0;
	height: auto;
	min-height: 90vh;
	
	.intro {
		margin: auto;
		max-width: 540px;
		text-align: center;
		position: relative;
		z-index: 1;
		img{
			max-width: 45%;
		}

		h1 {
			color: #FFF;
			font-size: 36px;
		}

		p {
			color: #FFF;
			font-size: 18px;
			margin-bottom: 0;
		}

		span {
			display: inline-block;

			label {
				margin-bottom: 0;

				&::after {
					content: attr(value);
				}
			}
		}
	}

	.social-icons {
		li {
			a {
				color: #FFF;
			}
		}
	}

	&.light {
		background: #F9F9FF;

		.intro {
			h1 {
				color: $colordark;
			}

			span,
			p {
				color: $colordefault;
			}
		}

		.social-icons {
			li {
				a {
					color: $colordark;

					&:hover {
						color: $coloryellow;
					}
				}
			}
		}
	}
}
.pheader{
	font-size: 36px;
	margin: 0;
	margin-left: 0px;
	position: relative;
	text-align: center;
}

.section-title {
	font-size: 36px;
	margin: 0;
	margin-left: 0px;
	position: relative;
	

	&:before {
		content: '';
		display: block;
		height: 37px;
		left: -14px;
		top: -14px;
		position: absolute;
		width: 37px;
	}
}

/*=================================================================*/
/*                      PORTFOLIO                              
/*=================================================================*/
.portfolio-wrapper {
	[class*="col-"] {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.pf-filter-wrapper {
	display: none;
}

.portfolio-item {
	position: relative;
	overflow: hidden;

	.thumb {
		overflow: hidden;
	}

	.details {
		color: #FFF;
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 1;

		h4 {
			color: #FFF;
			font-size: 20px;
			margin: 0 0 10px;
			padding: 0 20px;
			opacity: 0;
			transform: translateY(30px);
			transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
			position: absolute;
			top: 60px;
		}

		span.term {
			color: #FFF;
			background: $colorpink;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			font-size: 14px;
			opacity: 0;
			display: inline-block;
			padding: 3px 10px;
			position: absolute;
			top: 0;
			left: 20px;
			transform: translateY(-40px);
			transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		}

		.more-button {
			color: #FFF;
			font-size: 20px;
			display: block;
			background: $coloryellow;
			border-radius: 100%;
			height: 40px;
			line-height: 42px;
			text-align: center;
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: 40px;
			opacity: 0;
			@include transition(.3s);
		}
	}

	.mask {
		background: $colorblue;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		height: 100%;
		width: 100%;
		@include transition(.3s);
	}
	.tag {
		font-size: 10;
		color: black;
		padding-left: 20px;
	}

	&:hover {
		.mask {
			opacity: .9;
		}

		.details {

			h4,
			span {
				opacity: 1;
				transform: translateY(0);
			}

			.more-button {
				opacity: 1;
			}
		}
	}
}
/*=================================================================*/
/*                      Blog                              
/*=================================================================*/
.blog-post {
	background: #fff;
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post h3 {
	margin-top: 0;
  }
  
  .blog-post p {
	margin-bottom: 10px;
  }
  
  .blog-post .btn {
	text-decoration: none;
  }

  .pheader {
	margin-bottom: 20px; /* Adjust the value as needed */
  }

/*=================================================================*/
/*                      CONTACT                              
/*=================================================================*/
.contact-info {
	// background-image: url('/images/map.svg');
	background-repeat: no-repeat;
	background-size: contain;
	min-height: 80px;

	h3 {
		font-size: 23px;
		margin: 0 0 10px;
	}
}

/*=================================================================*/
/*                      Footer                              
/*=================================================================*/

footer.footer {
	background: $colordark;
	padding: 40px 0;
	text-align: center;

	.copyright {
		color: #9C9AB3;
		font-size: 14px;
	}

	&.light {
		background: #F9F9FF;
		border-top: solid 1px rgba(0, 0, 0, 0.05);
	}
}