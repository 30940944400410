/*=================================================================*/
/*                     BUTTONS
/*=================================================================*/
.btn {
    border-radius: 30px;
    font-family: $fonthead;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    line-height: 1;
    padding: 12px 32px;
    position: relative;

    &:focus {
        box-shadow: none;
    }
}

.btn:focus {
    outline: 0;
}

@keyframes button-push {
    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes hvr-push {
    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.btn-default {
    color: #FFF !important;
    background: $colorpink;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

    &:hover {
        color: #FFF;
        -webkit-animation-name: button-push;
        animation-name: button-push;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
}
