/*=================================================================*/
/*                      BASE                              
/*=================================================================*/
* {
    margin: 0;
    padding: 0;
}

html {
    margin: 0 !important;
    overflow: auto !important;
}

body {
    color: #5E5C7F;
    background-color: #F9F9FF;
    font-family: $fontstack;
    font-size: 16px;
    line-height: 1.7;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
    height: auto;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.relative {
    position: relative;
}

/*=================================================================*/
/*                      TYPOGRAPHY                              
/*=================================================================*/
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #454360;
    font-family: $fonthead;
    font-weight: 700;
    margin: 20px 0;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

a {
    color: $colorpink;
    outline: 0;
    @include transition(.3s);

    &:hover {
        color: $colordark;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}
